import React, { useState } from 'react';
import { Formik } from 'formik';
import TextField from 'lib/components/TextField';
import Text from 'lib/components/Text';
import Card from 'lib/containers/Card';
import ButtonLoading from 'lib/components/Button/ButtonLoading';
import { Container, Col, Row } from 'lib/containers/Grid';
import DisplayInit from 'lib/layouts/DisplayInit';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import imageBg from './assets/bg.png';
import { schema } from './schema';
import forgotPasswordRepository from 'repository/Admin/ForgotPassword';

function ForgotPassword() {
  const [isShowloading, setIsShowLoading] = useState(false);
  const { t, i18n } = useTranslation();

  function actionChangeSubmit(value, resetForm) {
    forgotPasswordRepository
      .requestForgotPasswordEmail({
        param: {
          email: value.email
        },
        loading: setIsShowLoading,
        language: i18n.language
      })
      .then(res => {
        if ([200, 201].includes(res?.status)) {
          resetForm();
          toast.success(
            <div>
              Success
              <br /> {res?.data.data.message}
            </div>,
            { hideProgressBar: true }
          );
        }
      });
  }

  return (
    <DisplayInit bg={imageBg}>
      <Container>
        <Row>
          <Col md={7} className="flex flex-col items-start justify-center min-h-screen text-white">
            <Text tag="span" variant="title" className="mb-16">
              {t('common:label.forgot_password')}
            </Text>

            <Text tag="span" variant="description">
              {t('common:forgot_password.label')}
            </Text>
          </Col>

          <Col md={5} className="flex items-center min-h-screen">
            {' '}
            <Card className="w-full px-48 py-56 bg-white">
              <img src="/logo/ksp_connect.svg" alt="logo" className="w-full mb-48" />
              <Formik
                initialValues={{ email: '' }}
                validationSchema={schema(t)}
                onSubmit={(value, { resetForm }) => actionChangeSubmit(value, resetForm)}
              >
                {({ values, errors, touched, handleChange, handleSubmit }) => (
                  <form onSubmit={handleSubmit}>
                    <TextField
                      name="email"
                      value={values.email}
                      alert={errors.email}
                      showAlert={errors.email && touched.email}
                      onChange={handleChange}
                      className="mb-262"
                      placeholder="Email"
                      prefix="envelope"
                    />

                    <div className="block">
                      <ButtonLoading
                        isShowloading={isShowloading}
                        text={t('common:label.send_email')}
                        rounded
                        size="large"
                        type="submit"
                        className="w-full"
                      />
                    </div>
                  </form>
                )}
              </Formik>
            </Card>
          </Col>
        </Row>
      </Container>
    </DisplayInit>
  );
}

export default ForgotPassword;
