import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import DisplayMaster from 'lib/layouts/DisplayMaster';
import cookie from 'react-cookies';
import PATH_URL from './path';

const RouterContainer = ({
  children,
  component: Component,
  auth = cookie.load('token'),
  ...rest
}) => (
  <Route
    {...rest}
    render={props => {
      return auth ? (
        <DisplayMaster {...props}>{children}</DisplayMaster>
      ) : (
        <Redirect to={{ pathname: PATH_URL.HOME, state: { from: props.location } }} />
      );
    }}
  />
);

export default RouterContainer;
