import classNames from 'classnames';
import Flex from 'lib/containers/Flex';
import React, { Fragment, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { setLocalStorage } from 'services/localStorage';
import style from './Button.module.scss';

const ButtonLanguage = ({ withIcon = true, reloadTrueFalse = false }) => {
  const { i18n } = useTranslation();
  const [languageSelected, setLanguageSelected] = useState('/img/icon-flag-ina.png');
  const [isShowOpen, setIsShowOpen] = useState(false);

  useEffect(() => {
    if (i18n.language === 'en') {
      setLanguageSelected('/img/icon-flag-eng.png');
    }
  }, [i18n.language]);

  const changeLanguage = (language, icon) => {
    setLanguageSelected(icon);
    setIsShowOpen(false);
    i18n.changeLanguage(language);
    setLocalStorage('language', language);
    if (reloadTrueFalse) {
      window.location.reload();
    }
  };

  const selectlanguagelistId = [
    {
      icon: '/img/icon-flag-ina.png',
      language: 'id'
    },
    {
      icon: '/img/icon-flag-eng.png',
      language: 'en'
    }
  ];

  const selectlanguagelistEn = [
    {
      icon: '/img/icon-flag-eng.png',
      language: 'en'
    },
    {
      icon: '/img/icon-flag-ina.png',
      language: 'id'
    }
  ];

  const languages = values =>
    values.map((lang, index) => {
      return (
        <span
          className={classNames(
            index >= 1 ? style.selectActive : style.selectNotActive,
            'flex flex-col rounded-md cursor-pointer'
          )}
          key={index}
          onClick={() => changeLanguage(lang.language, lang.icon)}
        >
          <img src={lang.icon} alt="language" className="w-24 h-24" />
        </span>
      );
    });

  return (
    <Flex items="center" justify="between" className="mb-24">
      {withIcon && <img src="/logo/ksp_connect.svg" alt="logo" className="w-8/12" />}
      <Fragment>
        {!isShowOpen && (
          <div
            onClick={() => setIsShowOpen(!isShowOpen)}
            className={classNames(
              style.selectNotActive,
              'flex flex-col rounded-md cursor-pointer ml-15'
            )}
          >
            <img src={languageSelected} alt="language" className="w-24 h-24" />
          </div>
        )}
        {isShowOpen && (
          <div className="flex flex-col ml-15">
            {i18n.language === 'en'
              ? languages(selectlanguagelistEn)
              : languages(selectlanguagelistId)}
          </div>
        )}
      </Fragment>
    </Flex>
  );
};

export default ButtonLanguage;
