const PREFIX_STORAGE = 'mora';

export const getLocalStorage = key => {
  const stored = localStorage.getItem(`${PREFIX_STORAGE}@${key}`);

  try {
    return JSON.parse(stored);
  } catch (e) {
    return stored;
  }
};

export const setLocalStorage = (key, value) => {
  const parsed = typeof value === 'object' ? JSON.stringify(value) : value;
  localStorage.setItem(`${PREFIX_STORAGE}@${key}`, parsed);
};

export const removeLocalStorage = key => {
  localStorage.removeItem(`${PREFIX_STORAGE}@${key}`);
};
