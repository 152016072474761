const SIGNIN = '/dashboard/admin/signin';
const VERIFY = '/dashboard/admin/verify';
const REGISTRASI = '/dashboard/registrations';
const CHANGE_PASSWORD = '/dashboard/admin/change-password';
const ADMINS = '/dashboard/admins';
const PINJAMAN = '/dashboard/disbursements';
const DAFTAR_PINJAMAN = '/dashboard/loans';
const CALLBACK = '/dashboard/callbacks';
const CREDENTIALS = '/dashboard/credentials';
const IP_WHITE_LISTS = '/dashboard/whitelists';
const DISBURSEMENT_CONFIRMATION = '/dashboard/settlement/disbursement/confirm';
const REPAYMENTS = '/dashboard/repayments';
const REPAYMENTS_DUE = '/dashboard/repayments/due';
const SETTELMENT_REPAYMENTS = '/dashboard/settlement/repayments/batch-code';
const CREATE_REPAYMENTS = '/dashboard/settlement/repayments/create';
const PAYMENTS_COMPLETED = '/dashboard/settlement/repayments';
const FORGOT_PASSWORD = '/dashboard/admin/forgot-password';
const CREATE_ACCOUNT = '/dashboard/admins/create';
const ANALYTIC_LOAN = '/dashboard/analytic/loan';
const SETTELMENTS = '/dashboard/loans/settlements';
const SIGNOUT = '/dashboard/admin/signout';

export const PATH_ADMIN = {
  SETTELMENTS,
  ANALYTIC_LOAN,
  SIGNIN,
  VERIFY,
  REGISTRASI,
  CHANGE_PASSWORD,
  ADMINS,
  PINJAMAN,
  DAFTAR_PINJAMAN,
  CALLBACK,
  CREDENTIALS,
  IP_WHITE_LISTS,
  DISBURSEMENT_CONFIRMATION,
  REPAYMENTS,
  REPAYMENTS_DUE,
  SETTELMENT_REPAYMENTS,
  CREATE_REPAYMENTS,
  PAYMENTS_COMPLETED,
  FORGOT_PASSWORD,
  CREATE_ACCOUNT,
  SIGNOUT
};
