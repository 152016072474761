const sidebars = t => [
  {
    title: 'Operations',
    menu: [
      {
        icon: 'regis',
        label: t('common:label.registration'),
        link: '/dashboard/registrasi'
      },
      {
        icon: 'daftarPinjaman',
        label: t('common:label.loan_list'),
        link: '/dashboard/daftar-pinjaman'
      },
      {
        icon: 'money_stack',
        label: t('common:sidebar_dashboard.disbursement'),
        link: '/dashboard/pencairan'
      },
      {
        icon: 'invoice',
        label: t('common:sidebar_dashboard.payment'),
        link: '/dashboard/pembayaran-selesai',
        linkTabs: ['/dashboard/pembayaran-baru', '/dashboard/pinjaman-jatuh-tempo']
      }
    ]
  },
  {
    title: 'Tools',
    menu: [
      {
        icon: 'laporan',
        label: t('common:sidebar_dashboard.report'),
        link: '/dashboard/laporan'
      },
      {
        icon: 'storage',
        label: t('common:sidebar_dashboard.developer_tools'),
        link: '/dashboard/developer-tools'
      }
    ]
  },
  {
    title: t('common:label.others'),
    menu: [
      {
        icon: 'user',
        label: t('common:sidebar_dashboard.manage_users'),
        link: '/dashboard/kelola-pengguna'
      }
    ]
  }
];

export { sidebars };
