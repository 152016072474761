import axios from 'axios';
import cookie from 'react-cookies';
import history from 'router/history';

const { REACT_APP_API_MAIN_SERVICE } = process.env;

const getToken = () => {
  return cookie.load('token');
};

const axiosInstance = axios.create({
  baseURL: REACT_APP_API_MAIN_SERVICE,
  timeout: 60 * 4 * 1000,
  headers: {
    'Content-Type': 'application/json'
  }
});

// Intercept axios response
axiosInstance.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    // Handle if not authorized
    if (error.response.status === 403) {
      history.push('/not-authorized');
    }

    return Promise.reject(error);
  }
);

export function setApiAcceptLanguage(language) {
  axiosInstance.defaults.headers.common = {
    ...axiosInstance.defaults.headers.common,
    'Accept-Language': language
  };
}

export const serviceWithTokenLanguage = language => {
  axiosInstance.defaults.headers.common = {
    ...axiosInstance.defaults.headers.common,
    Authorization: `Bearer ${getToken()}`
  };

  return axiosInstance;
};

export const serviceWithToken = (token = getToken()) => {
  axiosInstance.defaults.headers.common = {
    ...axiosInstance.defaults.headers.common,
    Authorization: `Bearer ${token}`
  };
  return axiosInstance;
};

export const serviceWithoutToken = () => {
  const headers = {};
  Object.keys(axiosInstance.defaults.headers.common)
    .filter(key => key.toLocaleLowerCase() !== 'authorization')
    .forEach(key => {
      headers[key] = axiosInstance.defaults.headers.common[key];
    });

  axiosInstance.defaults.headers.common = headers;

  return axiosInstance;
};
