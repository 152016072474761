import * as yup from 'yup';

export const schema = t =>
  yup.object().shape({
    old_password: yup
      .string()
      .required(t('common:required.old_password'))
      .matches(
        /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{8,64}$/,
        t('common:label.must_contain_character')
      ),
    new_password: yup
      .string()
      .required(t('common:required.new_password'))
      .matches(/^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{8,64}$/, t('common:label.must_contain_character'))
      .notOneOf([yup.ref('old_password'), null], t('common:label.cannot_be_the_same_passowrd')),
    confirm_password: yup
      .string()
      .required(t('common:required.confirm_new_password'))
      .oneOf([yup.ref('new_password'), null], t('common:label.password_must_match'))
      .matches(/^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{8,64}$/, t('common:label.must_contain_character'))
      .notOneOf([yup.ref('old_password'), null], t('common:label.cannot_be_the_same_passowrd'))
  });
