import React from 'react';
import FadeDrawer from 'lib/containers/FadeDrawer';
import Flex from 'lib/containers/Flex';
import Text from 'lib/components/Text';
import Button from 'lib/components/Button';
import PasswordField from 'lib/components/PasswordField';
import { Formik } from 'formik';
import { useTranslation } from 'react-i18next';
import { schema } from './schema';

const ChangePassword = ({ shown, onClose, actionSubmitResetPin, isShowLoading }) => {
  const { t } = useTranslation();

  return (
    <Formik
      initialValues={{ old_password: '', new_password: '', confirm_password: '' }}
      validationSchema={schema(t)}
      onSubmit={(value, { resetForm }) => {
        actionSubmitResetPin(value, resetForm);
      }}
    >
      {({ values, errors, touched, handleChange, handleBlur, handleSubmit, resetForm }) => (
        <FadeDrawer
          shown={shown}
          useOutsideEvent={false}
          width="38.6%"
          title={t('common:settings.heading')}
          onClose={() => onClose(resetForm)}
        >
          <div className="mt-32 p-25">
            <Text className="mb-24 font-black text-dark/42505c">
              {t('common:settings.heading_one')}
            </Text>
            <form onSubmit={handleSubmit}>
              <PasswordField
                placeholder={t('common:input.old_password')}
                name="old_password"
                alert={errors.old_password}
                onChange={handleChange}
                showAlert={errors.old_password && touched.old_password}
                className={'mb-24'}
                value={values.old_password}
              />
              <PasswordField
                placeholder={t('common:input.new_password')}
                alert={errors.new_password}
                onChange={handleChange}
                showAlert={errors.new_password && touched.new_password}
                name="new_password"
                className={'mb-24'}
                value={values.new_password}
              />
              <PasswordField
                placeholder={t('common:input.confirm_new_password')}
                alert={errors.confirm_password}
                onChange={handleChange}
                showAlert={errors.confirm_password && touched.confirm_password}
                name="confirm_password"
                className={'mb-32'}
                value={values.confirm_password}
              />

              <Flex justify="end">
                {isShowLoading ? (
                  <button type="button" className="w-full m-1 bg-rose-600" disabled>
                    <svg className="w-5 h-5 mr-3 animate-spin" viewBox="0 0 24 24" />
                    {t('common:label.process')}
                  </button>
                ) : (
                  <Button disabled={false} type="submit" size="medium">
                    {t('common:button.save')}
                  </Button>
                )}
              </Flex>
            </form>
          </div>
        </FadeDrawer>
      )}
    </Formik>
  );
};

export default ChangePassword;
