import React from 'react';
import cn from 'classnames';
import PropTypes from 'prop-types';
import style from './Text.module.scss';

const tags = ['h1', 'h2', 'h3', 'h4', 'h5', 'h6', 'p', 'ol', 'span', 'label', 'small'];
const variants = ['title', 'description'];

const Text = props => {
  const { tag: Tag, variant, children, className, ...rest } = props;

  const classMerge = cn({
    [style[variant]]: Boolean(variant)
  });

  return (
    <Tag {...rest} className={cn(classMerge, className)}>
      {children}
    </Tag>
  );
};

Text.defaultProps = {
  tag: 'p'
};

Text.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
  variant: PropTypes.oneOf(variants),
  tag: PropTypes.oneOf(tags).isRequired,
  className: PropTypes.string
};

export default Text;
