import React from 'react';
import { Link } from 'react-router-dom';
import Button from 'lib/components/Button';
import Text from 'lib/components/Text';
import Card from 'lib/containers/Card';
import { Container, Col, Row } from 'lib/containers/Grid';
import DisplayInit from 'lib/layouts/DisplayInit';
import PATH_URL from 'router/path';
import imageBg from './assets/bg.jpg';
import expiredImage from './assets/expired.svg';
import { useTranslation } from 'react-i18next';

const Expired = () => {
  const { t } = useTranslation();
  return (
    <DisplayInit bg={imageBg}>
      <Container>
        <Row>
          <Col md={12} className="flex flex-col items-center justify-center min-h-screen">
            <img
              src="/logo/ksp_connect.svg"
              alt="logo"
              className="w-1/4 px-24 mt-30 mb-30"
            />

            <Card className="flex flex-col items-center px-96 py-30 w-10/12">
              <Text tag="span" variant="title" className="mb-16">
                {t('common:settings.expired_heading')}
              </Text>

              <Text tag="span" variant="title" className="mb-16 text-3xl text-danger">
                Expired
              </Text>

              <img src={expiredImage} alt="Please Login" className="mb-30" />

              <Text tag="span" variant="description" className="text-lg">
                {t('common:settings.expired_desc')}
              </Text>

              <Button
                tag={Link}
                to={PATH_URL.HOME}
                size="large"
                className="mt-24 rounded-full px-48"
              >
                {t('common:label.back_to_home')}
              </Button>
            </Card>

            <Text className="text-center mt-30 px-96 w-6/12">
              &copy; {new Date().getFullYear()} {t('common:label.copryight')}
            </Text>
          </Col>
        </Row>
      </Container>
    </DisplayInit>
  );
};

export default Expired;
