import React from 'react';
import cn from 'classnames';
import style from './Card.module.scss';

const Card = ({ children, className, small, ...rest }) => {
  const mergeClass = cn(style.card, className, { [style.card__small]: small });

  return (
    <div {...rest} className={mergeClass}>
      {children}
    </div>
  );
};

export default Card;
