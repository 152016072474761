import React, { Fragment, useState } from 'react';
import cn from 'classnames';
import { Link, useLocation } from 'react-router-dom';
import Text from 'lib/components/Text';
import Icon from 'icon';
import { useTranslation } from 'react-i18next';
import style from './Sidebar.module.scss';
import { sidebars } from './Sidebar.Data';

const Sidebar = () => {
  const { pathname } = useLocation();
  const [shownSubmenu, setShownSubmenu] = useState('');
  const { t } = useTranslation();

  return (
    <div className={style.sidebar}>
      <div className="flex justify-center">
        <img src="/logo/ksp_connect.svg" alt="wordmark" className="w-9/12 mb-48" />
      </div>

      <div className={style.sidebar__menu}>
        <Link
          to="/dashboard"
          className={cn(style.sidebar__menu_item, 'mb-32', {
            [style.sidebar__menu_item_active]: ['/dashboard'].includes(pathname)
          })}
        >
          <Icon name="dashboard" size={24} />
          <span className={style.sidebar__menu_label}>
            {' '}
            {t('common:sidebar_dashboard.dashboard')}
          </span>
        </Link>

        {sidebars(t).map((v, i) => (
          <div key={i} className="mb-32">
            <Text className={style.sidebar__menu_title}>{v.title}</Text>

            {v.menu.map((el, k) => (
              <Fragment key={k}>
                {!el.submenu ? (
                  <Link
                    to={el.link}
                    className={cn(style.sidebar__menu_item, {
                      [style.sidebar__menu_item_active]: [el.link, ...(el.linkTabs ?? [])].includes(
                        pathname
                      )
                    })}
                  >
                    <Icon name={el.icon} size={24} />
                    <span className={style.sidebar__menu_label}>{el.label}</span>
                  </Link>
                ) : (
                  <div className={cn('relative', { 'bg-white/f9fbfc': shownSubmenu === el.label })}>
                    <button
                      type="button"
                      onClick={() => setShownSubmenu(shownSubmenu ? '' : el.label)}
                      className={cn(style.sidebar__menu_item, style.has_submenu)}
                    >
                      <div className="flex items-center">
                        <Icon name={el.icon} size={24} />
                        <span className={style.sidebar__menu_label}>{el.label}</span>
                      </div>

                      <Icon
                        name={shownSubmenu === el.label ? 'upper_arrow' : 'down_arrow'}
                        size={24}
                      />
                    </button>

                    {shownSubmenu === el.label &&
                      el.submenu.map((d, xt) => (
                        <Link
                          to={d.link}
                          key={xt}
                          className={cn(style.sidebar__menu_item, style.has_submenu)}
                        >
                          <span className={style.sidebar__menu_label}>{d.label}</span>
                        </Link>
                      ))}
                  </div>
                )}
              </Fragment>
            ))}
          </div>
        ))}
      </div>
    </div>
  );
};

export default Sidebar;
