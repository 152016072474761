const HOME = '/';
const FORGOT_PASSWORD = '/forgot-password';
const RESET_PASSWORD = '/reset-password';
const EXPIRED = '/expired';
const RESET_PASSWORD_SUCCESS = '/reset-password-success';
const DASHBOARD = '/dashboard';
const ADMIN = '/dashboard/admin';
const REGISTRASI = '/dashboard/registrasi';
const PENCAIRAN = '/dashboard/pencairan';
const DAFTAR_PINJAMAN = '/dashboard/daftar-pinjaman';
const PEMBAYARAN_SELESAI = '/dashboard/pembayaran-selesai';
const PEMBAYARAN_BARU = '/dashboard/pembayaran-baru';
const MATURED_LOAN = '/dashboard/pinjaman-jatuh-tempo';
const KELOLA_PENGGUNA = '/dashboard/kelola-pengguna';
const DEVELOPER_TOOLS = '/dashboard/developer-tools';
const LAPORAN = '/dashboard/laporan';
const NOT_AUTHORIZED = '/not-authorized';

const PATH_URL = {
  MATURED_LOAN,
  PEMBAYARAN_BARU,
  PEMBAYARAN_SELESAI,
  HOME,
  DASHBOARD,
  DEVELOPER_TOOLS,
  ADMIN,
  REGISTRASI,
  PENCAIRAN,
  FORGOT_PASSWORD,
  RESET_PASSWORD,
  RESET_PASSWORD_SUCCESS,
  KELOLA_PENGGUNA,
  DAFTAR_PINJAMAN,
  LAPORAN,
  EXPIRED,
  NOT_AUTHORIZED
};

export default PATH_URL;
