import PropTypes from 'prop-types';
import setIcons from 'icon/sets';

const PropsDefault = {
  type: 'text',
  disabled: false
};

const PropsModel = {
  onChange: PropTypes.func,
  prefixText: PropTypes.node,
  prefix: PropTypes.oneOf([...Object.keys(setIcons)]),
  suffix: PropTypes.oneOf([...Object.keys(setIcons)]),
  type: PropTypes.string,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  value: PropTypes.string,
  alert: PropTypes.string,
  showAlert: PropTypes.bool
};

export { PropsModel, PropsDefault };
