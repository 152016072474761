export const setCurrentUser = payload => ({
  type: 'APP_AUTH_SET_USER',
  payload
});

export const authFail = payload => ({
  type: 'APP_AUTH_FAIL',
  payload
});

export const authFailReset = payload => ({
  type: 'APP_AUTH_RESET'
});
