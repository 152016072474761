import React from 'react';
import cn from 'classnames';
import PropTypes from 'prop-types';
import style from './Grid.module.scss';

const tags = ['div', 'section', 'footer', 'header'];

const Container = props => {
  const { tag: Element, fluid, children, className } = props;
  const wrapper = cn(fluid ? style['container-fluid'] : style.container);

  return <Element className={cn(wrapper, className)}>{children}</Element>;
};

Container.defaultProps = {
  tag: 'div',
  fluid: false,
  className: ''
};

Container.propTypes = {
  tag: PropTypes.oneOf(tags).isRequired,
  fluid: PropTypes.bool,
  className: PropTypes.string
};

export default Container;
