import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import cn from 'classnames';
import Text from 'lib/components/Text';
import Icon from 'icon';
import style from './Button.module.scss';

const tags = ['button', 'a', Link];
const sizes = ['small', 'large', 'medium'];
const variants = ['primary', 'danger', 'secondary', 'default'];
const contents = ['no-icon', 'left-icon', 'right-icon', 'icon-only'];

const Button = props => {
  const {
    tag: Tag,
    size,
    variant,
    content,
    icon,
    iconSize,
    children,
    className,
    darkMode,
    disabled,
    rounded,
    ...rest
  } = props;

  const CreateIcon = () => (
    <Icon
      name={icon}
      size={iconSize}
      className={cn({
        [style['icon-left']]: content === 'left-icon',
        [style['icon-right']]: content === 'right-icon'
      })}
    />
  );

  const styleClass = cn(className, style.btn, style[variant], style[size], {
    [style.dark]: darkMode,
    [style.icon]: content === 'icon-only',
    [style.rounded]: rounded
  });

  return (
    <Tag {...rest} className={styleClass} disabled={disabled}>
      {content === 'left-icon' && <CreateIcon />}
      {content !== 'icon-only' && <Text tag="span">{children}</Text>}
      {content === 'right-icon' && <CreateIcon />}
      {content === 'icon-only' && <CreateIcon />}
    </Tag>
  );
};

Button.defaultProps = {
  tag: 'button',
  size: 'small',
  variant: 'primary',
  content: 'no-icon',
  icon: 'arrow_forward',
  iconSize: 24,
  className: '',
  darkMode: false,
  disabled: false
};

Button.propTypes = {
  tag: PropTypes.oneOf(tags).isRequired,
  size: PropTypes.oneOf(sizes).isRequired,
  variant: PropTypes.oneOf(variants).isRequired,
  content: PropTypes.oneOf(contents).isRequired,
  disabled: PropTypes.bool,
  icon: PropTypes.string,
  iconSize: PropTypes.number,
  className: PropTypes.string,
  darkMode: PropTypes.bool
};

export default Button;
