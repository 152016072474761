import React from 'react';
import cn from 'classnames';
import PropTypes from 'prop-types';
import style from './Grid.module.scss';

const cols = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 'auto'];

const Col = props => {
  const { children, sm, md, className } = props;

  const sizing = cn({
    [style[`col-${sm}`]]: sm !== 'auto',
    [style[`col-md-${md}`]]: sm !== md && md !== 'auto',
    'w-auto': sm === 'auto',
    'md:w-auto': md === 'auto'
  });

  return <div className={cn(sizing, className)}>{children}</div>;
};

Col.defaultProps = {
  sm: 0,
  md: 0,
  className: ''
};

Col.propTypes = {
  sm: PropTypes.oneOf(cols),
  md: PropTypes.oneOf(cols),
  className: PropTypes.string
};

export default Col;
