import { useTranslation } from 'react-i18next';
import { Container } from 'lib/containers/Grid';
import Image403 from '../../../assets/images/403.png';

const NotAuthorized = ({ linkTo }) => {
  const { t } = useTranslation();

  return (
    <Container fluid>
      <div className="flex flex-col items-center justify-center h-full">
        <img src={Image403} alt="403-img" style={{ height: '70vh' }} />
        <p className="pt-16 font-bold">{t('common:label.not_authorized')}</p>
      </div>
    </Container>
  );
};

export default NotAuthorized;
