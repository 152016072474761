import PropTypes from 'prop-types';
import cn from 'classnames';

const Loading = ({ className }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" className={cn(className)} viewBox="0 0 100 100">
      <path d="M21 50A29 29 0 0 0 79 50A29 33.2 0 0 1 21 50" fill="#1b77e2" stroke="none">
        <animateTransform
          attributeName="transform"
          type="rotate"
          dur="0.8s"
          repeatCount="indefinite"
          keyTimes="0;1"
          values="0 50 52.1;360 50 52.1"
        ></animateTransform>
      </path>
    </svg>
  );
};

Loading.defaultProps = {
  className: 'h-100 w-100'
};

Loading.propTypes = {
  className: PropTypes.string
};

export default Loading;
