import React from 'react';
import { useTranslation } from 'react-i18next';
import Button from '.';

export default function ButtonLoading({ isShowloading, text, ...rest }) {
  const { t } = useTranslation();
  return (
    <div>
      {isShowloading ? (
        <Button size="medium" {...rest} disabled>
          <svg className="animate-spin h-5 w-5 mr-3 ..." viewBox="0 0 24 24">
            ...
          </svg>
          {t('common:label.process')} ...
        </Button>
      ) : (
        <Button size="medium" {...rest}>
          {text}
        </Button>
      )}
    </div>
  );
}
