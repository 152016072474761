const auth = `
{
    status: status,
    token: data.data.sid,
    error: status != to_number('200') && data,
    data : data
}
`;

export default auth;
