import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import RouteManager from 'router';
import ApplicationMode from 'lib/components/ApplicationMode';
import { getLocalStorage } from 'services/localStorage';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import './internationalization';
import { setApiAcceptLanguage } from 'services/api/httpClient';

function onQueryError(error: unknown) {
  const response = error.response;
  const errorMessage = response?.data.message ?? response?.data.errorMessage;
  const errorDescription = errorMessage ?? response?.statusText;

  toast.error(
    <div>
      Error
      <br /> {errorDescription}
    </div>
  );
}

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      onError: onQueryError,
      refetchOnWindowFocus: false,
      retry: 2
    },
    mutations: {
      onError: onQueryError
    }
  }
});

const App = () => {
  const { i18n } = useTranslation();
  const [initialized, setInitialized] = useState(false);

  const changeLanguage = language => {
    i18n.changeLanguage(language);
  };

  useEffect(() => {
    setApiAcceptLanguage(i18n.language);
  }, [i18n.language]);

  useEffect(() => {
    toast.configure({
      hideProgressBar: true,
      bodyStyle: {
        fontFamily: 'Lato, sans-serif'
      }
    });
    if (!initialized) {
      changeLanguage(getLocalStorage('language') || 'en');
      setInitialized(true);
    }
  }, []);

  return (
    <QueryClientProvider client={queryClient}>
      <RouteManager />
      <ApplicationMode />
      <ReactQueryDevtools />
    </QueryClientProvider>
  );
};

export default App;
