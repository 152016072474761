import PropTypes from 'prop-types';

const tags = ['div', 'span', 'header'];
const justifies = ['start', 'end', 'center', 'between', 'around', 'evenly'];
const alignItems = ['start', 'end', 'center', 'baseline', 'stretch'];
const directions = ['row', 'row-reverse', 'col', 'col-reverse'];

const PropsModel = {
  justify: PropTypes.oneOf(justifies),
  items: PropTypes.oneOf(alignItems),
  direction: PropTypes.oneOf(directions),
  tag: PropTypes.oneOf(tags),
  inline: PropTypes.bool,
  gap: PropTypes.number,
  className: PropTypes.string
};

const PropsDefault = {
  inline: false,
  tag: 'div'
};

export { PropsModel, PropsDefault };
