import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import style from './Grid.module.scss';

const Row = props => {
  const { children, reverse, className } = props;

  const mergeClass = cn(style.row, className, {
    [style.reverse]: reverse
  });

  return <div className={mergeClass}>{children}</div>;
};

Row.defaultProps = {
  reverse: false,
  className: ''
};

Row.propTypes = {
  reverse: PropTypes.bool,
  className: PropTypes.string
};

export default Row;
