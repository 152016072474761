import React, { useState, useEffect } from 'react';
import ButtonLoading from 'lib/components/Button/ButtonLoading';
import PasswordField from 'lib/components/PasswordField';
import Text from 'lib/components/Text';
import Card from 'lib/containers/Card';
import Flex from 'lib/containers/Flex';
import { Container, Col, Row } from 'lib/containers/Grid';
import DisplayInit from 'lib/layouts/DisplayInit';
import PATH_URL from 'router/path';
import { Formik } from 'formik';
import { useTranslation } from 'react-i18next';
import history from 'router/history';
import { schema } from './schema';
import imageBg from './assets/bg.png';
import forgotPasswordRepository from 'repository/Admin/ForgotPassword';

const ResetPassword = props => {
  const [load, setload] = useState(false);
  const [isShowloading, setIsShowLoading] = useState(false);
  const { t, i18n } = useTranslation();
  const DELAY = 100;

  useEffect(() => {
    forgotPasswordRepository
      .validateForgotPasswordEmail({ unique_id: props.match.params.id })
      .then(res => {
        if ([200, 201].includes(res?.status)) {
          setTimeout(() => {
            setload(true);
          }, DELAY);
        } else {
          history.push(PATH_URL.EXPIRED);
        }
      });
  }, []);

  const actionChangeSubmit = async value => {
    const params = Object.assign(value);
    forgotPasswordRepository
      .resetPasswordEmail({
        param: params,
        loading: setIsShowLoading,
        unique_id: props.match.params.id,
        language: i18n.language
      })
      .then(res => {
        if ([200, 201].includes(res?.status)) {
          history.push(PATH_URL.RESET_PASSWORD_SUCCESS);
        }
      });
  };

  return (
    <DisplayInit bg={imageBg}>
      <Container>
        <Row>
          <Col md={5} className="flex flex-col items-center justify-center min-h-screen">
            <Formik
              initialValues={{ new_password: '', confirm_password: '' }}
              validationSchema={schema(t)}
              onSubmit={value => actionChangeSubmit(value)}
            >
              {({ values, errors, touched, handleChange, handleBlur, handleSubmit }) => (
                <form onSubmit={handleSubmit}>
                  {' '}
                  <Card className="overflow-hidden">
                    <div className="px-48 py-56">
                      <img
                        src="/logo/ksp_connect.svg"
                        alt="logo"
                        className="w-full mb-48"
                      />

                      <PasswordField
                        onChange={handleChange}
                        disabled={!load}
                        name="new_password"
                        alert={errors.new_password}
                        showAlert={errors.new_password && touched.new_password}
                        className="mb-10"
                        placeholder={t('common:input.new_password')}
                      />

                      <PasswordField
                        onChange={handleChange}
                        disabled={!load}
                        name="confirm_password"
                        alert={errors.confirm_password}
                        value={values.confirm_password}
                        showAlert={errors.confirm_password && touched.confirm_password}
                        className="mb-10"
                        placeholder={t('common:input.confirm_new_password')}
                      />

                      <div className="block mt-100">
                        {load && (
                          <ButtonLoading
                            isShowloading={isShowloading}
                            text={t('common:button.save')}
                            rounded
                            type="submit"
                            size="large"
                            className="w-full"
                          />
                        )}
                      </div>
                    </div>
                  </Card>
                  <Text className="text-center mt-30 px-70">
                    &copy; {new Date().getFullYear()} {t('common:label.copryight')}
                  </Text>
                </form>
              )}
            </Formik>
          </Col>

          <Col md={7} className="flex items-center justify-end min-h-screen">
            <Flex justify="end" direction="col" className="md:w-9/12">
              <Text tag="h1" variant="title" className="mb-16">
                {t('common:settings.reset_password_heading')}
              </Text>

              <Text>{t('common:settings.reset_password_heading_two')}</Text>
            </Flex>
          </Col>
        </Row>
      </Container>
    </DisplayInit>
  );
};

export default ResetPassword;
