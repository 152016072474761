import React from 'react';
import cn from 'classnames';
import { PropsDefault, PropsModel } from './Flex.Types';

const Flex = props => {
  const { tag: Tag, children, direction, justify, items, gap, inline, className, ...rest } = props;

  const mergeClass = cn(inline ? 'inline-flex' : 'flex', className, {
    [`flex-${direction}`]: direction,
    [`justify-${justify}`]: justify,
    [`items-${items}`]: items
  });

  return (
    <Tag {...rest} className={mergeClass} style={{ gap }}>
      {children}
    </Tag>
  );
};

Flex.defaultProps = PropsDefault;
Flex.propTypes = PropsModel;

export default Flex;
