import Loading from './Loading';

const FallbackLoading = () => {
  return (
    <div className="flex justify-center h-full">
      <Loading />
    </div>
  );
};

export default FallbackLoading;
