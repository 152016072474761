import React, { useState } from 'react';
import cn from 'classnames';
import Icon from 'icon';
import randomId from 'util/randomId';
import Text from 'lib/components/Text';
import style from './TextInput.module.scss';
import { PropsModel, PropsDefault } from './TextInput.Types';

const TextInput = props => {
  const [getId] = useState(randomId());
  const {
    onChange,
    type,
    className,
    value,
    prefix,
    prefixText,
    suffix,
    disabled,
    alert,
    showAlert,
    ...rest
  } = props;

  const mergeInputClass = cn({
    [style.input__prefixed]: Boolean(prefix),
    [style.input__suffixed]: Boolean(suffix)
  });

  return (
    <div className={cn(className)}>
      <div className={cn(style.input, { [style.input__disabled]: disabled })}>
        {Boolean(prefix) && (
          <Icon
            name={prefix}
            size={22}
            className={cn(style.input__icon, style.input__icon_prefix)}
          />
        )}
        {Boolean(prefixText) && prefixText}
        <input
          {...rest}
          id={getId}
          type={type}
          value={value}
          disabled={disabled}
          className={cn(style.input__field, mergeInputClass)}
          onChange={onChange}
        />
        {Boolean(suffix) && (
          <Icon
            name={suffix}
            size={22}
            className={cn(style.input__icon, style.input__icon_suffix)}
          />
        )}
      </div>{' '}
      {Boolean(showAlert) && (
        <Text tag="span" className={cn('text-danger text-xs')}>
          {alert}
        </Text>
      )}
    </div>
  );
};

TextInput.defaultProps = PropsDefault;
TextInput.propTypes = PropsModel;

export default TextInput;
