import React from 'react';
import cn from 'classnames';
import style from './DisplayInit.module.scss';

const DisplayInit = ({ children, className, bg }) => (
  <div
    style={{ backgroundImage: `url(${bg})`, height: window.innerHeight }}
    className={cn(style.display, className)}
  >
    {children}
  </div>
);

export default DisplayInit;
