import style from './ApplicationMode.module.scss';

const environment = process.env.REACT_APP_MODE_ENV;
const isDevelopment = environment === 'development' || environment === 'testing';

function ApplicationMode() {
  if (isDevelopment) {
    return (
      <div className={style.ribbon}>
        <span className="bg-success">{environment} MODE</span>
      </div>
    );
  }

  return null;
}

export default ApplicationMode;
