import React from 'react';
import Sidebar from 'lib/layouts/Sidebar';
import Header from 'lib/layouts/Header';
import Footer from 'lib/layouts/Footer';
import style from './DisplayMaster.module.scss';

const DisplayMaster = ({ children }) => (
  <div className={style.master}>
    <Sidebar />

    <div id="master-content" className={style.master__main}>
      <Header />

      <div className={style.master__content}>{children}</div>

      <Footer />
    </div>
    {process.env.REACT_APP_MODE_ENV === 'development' && (
      <div className={style.master__ribbon}>
        <span className={style.master__ribbon__span}> DEVELOPMENT MODE</span>
      </div>
    )}
  </div>
);

export default DisplayMaster;
