export const capitalizeReplaceEtc = string => {
  return (
    <span style={{ textTransform: 'capitalize' }}>
      {string.replace(/[.*+?^${}()|[\]\\-_]/g, ' ')}
    </span>
  );
};

export const stringToArrayBuffer = s => {
  const buf = new ArrayBuffer(s.length);
  const view = new Uint8Array(buf);
  for (let i = 0; i !== s.length; ++i) view[i] = s.charCodeAt(i) & 0xff; // eslint-disable-line no-bitwise
  return buf;
};

export const regexEmail = RegExp(
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
);
export const regexName = RegExp(/(?=.*[a-zA-Z])[a-zA-Z .]+$/);
export const regexPassword = RegExp(/(?=.*[a-zA-Z])(?=.*[0-9])[a-zA-Z0-9]{6,}/);
