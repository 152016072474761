import React from 'react';
import PropTypes from 'prop-types';
import iconPath from './sets';

const types = Object.keys(iconPath);

const Icon = ({ size, name, className }) => (
  <svg
    viewBox={iconPath[name].box}
    width={size}
    height={size}
    className={`fill-current ${className}`}
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    {iconPath[name].path.map((item, i) => (
      <path key={i} fillRule="evenodd" d={item} />
    ))}
  </svg>
);

Icon.defaultProps = {
  size: 16,
  className: ''
};

Icon.propTypes = {
  name: PropTypes.oneOf(types).isRequired,
  size: PropTypes.number.isRequired,
  className: PropTypes.string
};

export default Icon;
