import * as yup from 'yup';

export const schema = t =>
  yup.object().shape({
    email: yup
      .string()
      .min(8, 'Email min 8')
      .max(50, 'Email max 50')
      .email(t('common:label.invalid_email'))
      .required(t('common:required.email')),
    password: yup
      .string()
      .required(t('common:required.password'))
      .min(8, `${t('common:input.password')} min 8`)
      .max(64, `${t('common:input.password')} max 64`),
    recaptcha_token: yup.string().required(t('common:global.catpcha_must_be_checked'))
  });
