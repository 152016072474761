import {
  apiPostWithoutToken,
  apiDeleteWithTokenLanguage,
  apiPostWithToken,
  apiPostWithTokenLanguage,
  apiPutWithToken
} from '../../services/api';
import jmespath from 'jmespath';
import { PATH_ADMIN } from '../../services/path/admin';
import responseGlobal from 'repository/Admin/ResponseGlobal/responseGlobal';
import auth from './response/auth';

async function login(props) {
  const loading = props.loading ? props.loading : function () {};
  let response = '';
  loading(true);
  try {
    response = await apiPostWithTokenLanguage(PATH_ADMIN.SIGNIN, props.param, props.language);
    response = jmespath.search(response, auth);
    loading(false);
  } catch (error) {
    response = jmespath.search(error.response, auth);
    loading(false);
  }
  return response;
}

async function verifyAuth(props) {
  const loading = props.loading ? props.loading : function () {};
  let response = '';
  loading(true);
  try {
    response = await apiPostWithToken(PATH_ADMIN.VERIFY, props.param);
    response = jmespath.search(response, responseGlobal);
    loading(false);
  } catch (error) {
    response = jmespath.search(error.response, responseGlobal);
    loading(false);
  }
  return response;
}

async function resetPin(props) {
  const loading = props.loading ? props.loading : function () {};
  let response = '';
  loading(true);
  try {
    response = await apiPutWithToken(PATH_ADMIN.CHANGE_PASSWORD, props.param);
    response = jmespath.search(response, responseGlobal);
    loading(false);
  } catch (error) {
    response = jmespath.search(error.response, responseGlobal);
    loading(false);
  }
  return response;
}

async function logout(props) {
  const loading = props.loading ? props.loading : function () {};
  let response = '';
  loading(true);
  try {
    response = await apiDeleteWithTokenLanguage(PATH_ADMIN.SIGNOUT, props.param, props.language);
    response = jmespath.search(response, auth);
    loading(false);
  } catch (error) {
    response = jmespath.search(error.response, auth);
    loading(false);
  }
  return response;
}

const authentication = {
  login,
  verifyAuth,
  resetPin,
  logout
};

export default authentication;
