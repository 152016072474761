import jwtDecode from 'jwt-decode';
import { getLocalStorage, removeLocalStorage } from 'services/localStorage';
import cookie from 'react-cookies';

const localToken = getLocalStorage('token');
const user = localToken ? jwtDecode(localToken) : false;

if (localToken && user.exp < Date.now() / 1000) {
  removeLocalStorage('token');
}

const initialState = {
  isAuthenticated: cookie.load('token'),
  user: user || {},
  authFail: false
};

export default function index(state = initialState, action) {
  switch (action.type) {
    case 'APP_AUTH_SET_USER':
      return {
        ...state,
        isAuthenticated: action?.payload?.token,
        user: action.payload?.data?.data
      };
    case 'APP_AUTH_FAIL':
      return {
        ...state,
        authFail: action.payload
      };
    case 'APP_AUTH_RESET':
      return {
        ...state,
        authFail: false
      };
    default:
      return state;
  }
}
