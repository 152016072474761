import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import './NotFound.scss';
import { NotFoundSvg } from 'assets/images/404';

const NotFound = ({ linkTo }) => {
  return (
    <div className="flex items-center justify-center h-screen px-5 text-gray-700">
      <div className="flex-col">
        <NotFoundSvg />
        <div className="flex justify-center mt-60">
          <div className="flex-col">
            <Link
              to={linkTo}
              className="inline px-16 py-8 text-sm font-medium leading-5 text-white transition-colors duration-150 border border-transparent rounded-lg shadow ml-50 focus:outline-none focus:shadow-outline-blue bg-azul active:bg-blue-600 hover:bg-blue-700"
            >
              Back to Homepage
            </Link>
            <p className="mt-32 ml-10 font-bold">Oops, halaman tidak ditemukan!</p>
          </div>
        </div>
      </div>
    </div>
  );
};

NotFound.defaultProps = {
  linkTo: '/'
};

NotFound.propTypes = {
  linkTo: PropTypes.string
};

export default NotFound;
