const icons = {
  hamburger_menu: {
    path: [
      'M3.25 8.67h19.5a1.08 1.08 0 0 0 0-2.17H3.25a1.08 1.08 0 0 0 0 2.17Zm19.5 8.66H3.25a1.08 1.08 0 0 0 0 2.17h19.5a1.08 1.08 0 1 0 0-2.17Zm0-5.41H3.25a1.08 1.08 0 1 0 0 2.16h19.5a1.08 1.08 0 1 0 0-2.16Z'
    ],
    box: '0 0 24 24'
  },
  close: {
    path: [
      'm13.41 12 6.3-6.29a1 1 0 1 0-1.42-1.42L12 10.59l-6.29-6.3a1 1 0 0 0-1.42 1.42l6.3 6.29-6.3 6.29A1 1 0 0 0 5 20.01a1 1 0 0 0 .71-.3l6.29-6.3 6.29 6.3a1 1 0 1 0 1.42-1.42L13.41 12Z'
    ],
    box: '0 0 24 24'
  },
  envelope: {
    path: [
      'M19 4H5a3 3 0 0 0-3 3v10a3 3 0 0 0 3 3h14a3 3 0 0 0 3-3V7a3 3 0 0 0-3-3zM5 6h14a1 1 0 0 1 1 1l-8 4.88L4 7a1 1 0 0 1 1-1zm15 11a1 1 0 0 1-1 1H5a1 1 0 0 1-1-1V9.28l7.48 4.57a1 1 0 0 0 1 0L20 9.28V17z'
    ],
    box: '0 0 24 24'
  },
  lock: {
    path: [
      'M12 13a1 1 0 0 0-1 1v3a1 1 0 0 0 2 0v-3a1 1 0 0 0-1-1zm5-4V7A5 5 0 0 0 7 7v2a3 3 0 0 0-3 3v7a3 3 0 0 0 3 3h10a3 3 0 0 0 3-3v-7a3 3 0 0 0-3-3zM9 7a3 3 0 1 1 6 0v2H9V7zm9 12a1 1 0 0 1-1 1H7a1 1 0 0 1-1-1v-7a1 1 0 0 1 1-1h10a1 1 0 0 1 1 1v7z'
    ],
    box: '0 0 24 24'
  },
  dashboard: {
    path: [
      'M10 13H3a1 1 0 0 0-1 1v7a1 1 0 0 0 1 1h7a1 1 0 0 0 1-1v-7a1 1 0 0 0-1-1zm-1 7H4v-5h5v5zM21 2h-7a1 1 0 0 0-1 1v7a1 1 0 0 0 1 1h7a1 1 0 0 0 1-1V3a1 1 0 0 0-1-1zm-1 7h-5V4h5v5zm1 4h-7a1 1 0 0 0-1 1v7a1 1 0 0 0 1 1h7a1 1 0 0 0 1-1v-7a1 1 0 0 0-1-1zm-1 7h-5v-5h5v5zM10 2H3a1 1 0 0 0-1 1v7a1 1 0 0 0 1 1h7a1 1 0 0 0 1-1V3a1 1 0 0 0-1-1zM9 9H4V4h5v5z'
    ],
    box: '0 0 24 24'
  },
  regis: {
    path: [
      'M11 20H5a1 1 0 0 1-1-1V5a1 1 0 0 1 1-1h5v3a3 3 0 0 0 3 3h3v5a1 1 0 0 0 2 0V8.94a1.31 1.31 0 0 0-.06-.27v-.09a1.07 1.07 0 0 0-.19-.28l-6-6a1.07 1.07 0 0 0-.28-.19.29.29 0 0 0-.1 0 1.1 1.1 0 0 0-.31-.11H5a3 3 0 0 0-3 3v14a3 3 0 0 0 3 3h6a1 1 0 0 0 0-2zm1-14.59L14.59 8H13a1 1 0 0 1-1-1V5.41zM7 14h6a1 1 0 0 0 0-2H7a1 1 0 0 0 0 2zm4 2H7a1 1 0 0 0 0 2h4a1 1 0 0 0 0-2zm-4-6h1a1 1 0 1 0 0-2H7a1 1 0 1 0 0 2zm13.71 6.29a1 1 0 0 0-1.42 0L16 19.59l-1.29-1.3a1 1 0 1 0-1.42 1.42l2 2a1 1 0 0 0 1.42 0l4-4a1 1 0 0 0 0-1.42z'
    ],
    box: '0 0 24 24'
  },
  pinjaman: {
    path: [
      'M12 12a3 3 0 1 0 0 6 3 3 0 0 0 0-6zm0 4a1 1 0 1 1 0-2 1 1 0 0 1 0 2zm-.71-6.29a1 1 0 0 0 .33.21.94.94 0 0 0 .76 0 1 1 0 0 0 .33-.21L15 7.46A1.03 1.03 0 1 0 13.54 6l-.54.59V3a1 1 0 0 0-2 0v3.59L10.46 6A1.03 1.03 0 1 0 9 7.46l2.29 2.25zM19 15a1 1 0 1 0-2 0 1 1 0 0 0 2 0zm1-7h-3a1 1 0 0 0 0 2h3a1 1 0 0 1 1 1v8a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1v-8a1 1 0 0 1 1-1h3a1 1 0 1 0 0-2H4a3 3 0 0 0-3 3v8a3 3 0 0 0 3 3h16a3 3 0 0 0 3-3v-8a3 3 0 0 0-3-3zM5 15a1 1 0 1 0 2 0 1 1 0 0 0-2 0z'
    ],
    box: '0 0 24 24'
  },
  daftarPinjaman: {
    path: [
      'M13 14H9a1 1 0 0 0 0 2h4a1 1 0 0 0 0-2zm4-10h-1.18A3 3 0 0 0 13 2h-2a3 3 0 0 0-2.82 2H7a3 3 0 0 0-3 3v12a3 3 0 0 0 3 3h10a3 3 0 0 0 3-3V7a3 3 0 0 0-3-3zm-7 1a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v1h-4V5zm8 14a1 1 0 0 1-1 1H7a1 1 0 0 1-1-1V7a1 1 0 0 1 1-1h1v1a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V6h1a1 1 0 0 1 1 1v12zm-3-9H9a1 1 0 1 0 0 2h6a1 1 0 0 0 0-2z'
    ],
    box: '0 0 24 24'
  },
  pembayaran: {
    path: [
      'm10.46 6 .54-.6V9a1 1 0 0 0 2 0V5.4l.54.56A1 1 0 0 0 15 6a1 1 0 0 0 0-1.42l-2.29-2.3a1 1 0 0 0-.33-.2 1 1 0 0 0-.76 0 1 1 0 0 0-.33.2L9 4.55A1.03 1.03 0 0 0 10.46 6zM12 12a3 3 0 1 0 0 6 3 3 0 0 0 0-6zm0 4a1 1 0 1 1 0-2 1 1 0 0 1 0 2zm-7-1a1 1 0 1 0 2 0 1 1 0 0 0-2 0zm14 0a1 1 0 1 0-2 0 1 1 0 0 0 2 0zm1-7h-4a1 1 0 0 0 0 2h4a1 1 0 0 1 1 1v8a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1v-8a1 1 0 0 1 1-1h4a1 1 0 1 0 0-2H4a3 3 0 0 0-3 3v8a3 3 0 0 0 3 3h16a3 3 0 0 0 3-3v-8a3 3 0 0 0-3-3z'
    ],
    box: '0 0 24 24'
  },
  report: {
    path: [
      'M19 12h-7V5c0-.6-.4-1-1-1-5 0-9 4-9 9s4 9 9 9 9-4 9-9c0-.6-.4-1-1-1zm-7 7.9c-3.8.6-7.4-2.1-7.9-5.9-.6-3.8 2.1-7.4 5.9-7.9V13c0 .6.4 1 1 1h6.9a6.8 6.8 0 0 1-5.9 5.9zM15 2c-.6 0-1 .4-1 1v6c0 .6.4 1 1 1h6c.6 0 1-.4 1-1 0-3.9-3.1-7-7-7zm1 6V4.1c2 .4 3.5 1.9 3.9 3.9H16z'
    ],
    box: '0 0 24 24'
  },
  storage: {
    path: [
      'M8 16.5a1 1 0 1 0 0 2 1 1 0 0 0 0-2zM12 2C8 2 4 3.37 4 6v12c0 2.63 4 4 8 4s8-1.37 8-4V6c0-2.63-4-4-8-4zm6 16c0 .71-2.28 2-6 2s-6-1.29-6-2v-3.27A13.16 13.16 0 0 0 12 16a13.16 13.16 0 0 0 6-1.27V18zm0-6c0 .71-2.28 2-6 2s-6-1.29-6-2V8.73A13.16 13.16 0 0 0 12 10a13.16 13.16 0 0 0 6-1.27V12zm-6-4C8.28 8 6 6.71 6 6s2.28-2 6-2 6 1.29 6 2-2.28 2-6 2zm-4 2.5a1 1 0 1 0 0 2 1 1 0 0 0 0-2z'
    ],
    box: '0 0 24 24'
  },
  user: {
    path: [
      'M12 2a10.02 10.02 0 0 0-9.16 5.99 10 10 0 0 0 1.8 10.79 10.02 10.02 0 0 0 14.72 0 10 10 0 0 0 1.8-10.79A10.02 10.02 0 0 0 12 2zm0 18.02a8.02 8.02 0 0 1-5.56-2.25 6.01 6.01 0 0 1 11.12 0A8.02 8.02 0 0 1 12 20.02zM10 10a2 2 0 1 1 4 0 2 2 0 0 1-4 0zm8.92 6a8.01 8.01 0 0 0-3.91-3.38 4 4 0 0 0-.24-5.54 4.01 4.01 0 0 0-5.54 0 4 4 0 0 0-.24 5.54 8.01 8.01 0 0 0-3.91 3.38 7.92 7.92 0 0 1-1.1-4 8.01 8.01 0 0 1 16.04 0 7.92 7.92 0 0 1-1.1 4z'
    ],
    box: '0 0 24 24'
  },
  search: {
    path: [
      'm16.29 15.23-2.78-2.76a6.75 6.75 0 0 0-.76-9.25 6.74 6.74 0 0 0-9.27.26 6.75 6.75 0 0 0-.26 9.28 6.74 6.74 0 0 0 9.25.76l2.75 2.76a.75.75 0 0 0 1.07 0 .75.75 0 0 0 0-1.05zm-8.03-1.71a5.25 5.25 0 0 1-5.25-5.25c0-2.9 2.35-5.25 5.25-5.25a5.25 5.25 0 0 1 3.71 8.96 5.25 5.25 0 0 1-3.7 1.54z'
    ],
    box: '0 0 18 18'
  },
  keluar: {
    path: [
      'M4 12a1 1 0 0 0 1 1h7.59l-2.3 2.29a1 1 0 0 0 0 1.42 1 1 0 0 0 1.42 0l4-4a1 1 0 0 0 .21-.33 1 1 0 0 0 0-.76 1 1 0 0 0-.21-.33l-4-4a1 1 0 1 0-1.42 1.42l2.3 2.29H5a1 1 0 0 0-1 1zM17 2H7a3 3 0 0 0-3 3v3a1 1 0 1 0 2 0V5a1 1 0 0 1 1-1h10a1 1 0 0 1 1 1v14a1 1 0 0 1-1 1H7a1 1 0 0 1-1-1v-3a1 1 0 0 0-2 0v3a3 3 0 0 0 3 3h10a3 3 0 0 0 3-3V5a3 3 0 0 0-3-3z'
    ],
    box: '0 0 24 24'
  },
  edit: {
    path: [
      'M5 18h4.24a1 1 0 0 0 .71-.29l6.92-6.93L19.71 8a1 1 0 0 0 0-1.42L15.47 2.3a1 1 0 0 0-1.42 0l-2.82 2.83-6.94 6.92a1 1 0 0 0-.29.71V17a1 1 0 0 0 1 1zm9.76-13.58 2.83 2.83-1.42 1.41-2.83-2.83 1.42-1.41zM6 13.17l5.93-5.93 2.83 2.83L8.83 16H6v-2.83zM21 20H3a1 1 0 1 0 0 2h18a1 1 0 1 0 0-2z'
    ],
    box: '0 0 24 24'
  },
  copy: {
    path: [
      'M21 8.94a1.31 1.31 0 0 0-.06-.27v-.09a1.07 1.07 0 0 0-.19-.28l-6-6a1.07 1.07 0 0 0-.28-.19.32.32 0 0 0-.09 0 .88.88 0 0 0-.33-.11H10a3 3 0 0 0-3 3v1H6a3 3 0 0 0-3 3v10a3 3 0 0 0 3 3h8a3 3 0 0 0 3-3v-1h1a3 3 0 0 0 3-3V8.94zm-6-3.53L17.59 8H16a1 1 0 0 1-1-1V5.41zM15 19a1 1 0 0 1-1 1H6a1 1 0 0 1-1-1V9a1 1 0 0 1 1-1h1v7a3 3 0 0 0 3 3h5v1zm4-4a1 1 0 0 1-1 1h-8a1 1 0 0 1-1-1V5a1 1 0 0 1 1-1h3v3a3 3 0 0 0 3 3h3v5z'
    ],
    box: '0 0 24 24'
  },
  eye: {
    path: [
      'M21.92 11.6C19.9 6.91 16.1 4 12 4s-7.9 2.91-9.92 7.6a1 1 0 0 0 0 .8C4.1 17.09 7.9 20 12 20s7.9-2.91 9.92-7.6a1 1 0 0 0 0-.8zM12 18c-3.17 0-6.17-2.29-7.9-6C5.83 8.29 8.83 6 12 6s6.17 2.29 7.9 6c-1.73 3.71-4.73 6-7.9 6zm0-10a4 4 0 1 0 0 8 4 4 0 0 0 0-8zm0 6a2 2 0 1 1 0-4 2 2 0 0 1 0 4z'
    ],
    box: '0 0 24 24'
  },
  warning_circle: {
    path: [
      'M11 15h2v2h-2v-2zm0-8h2v6h-2V7zm.99-5C6.47 2 2 6.48 2 12s4.47 10 9.99 10C17.52 22 22 17.52 22 12S17.52 2 11.99 2zM12 20c-4.42 0-8-3.58-8-8s3.58-8 8-8 8 3.58 8 8-3.58 8-8 8z'
    ],
    box: '0 0 24 24'
  },
  phone: {
    path: [
      'M19.521 13.032c-.22 0-.452-.07-.672-.12a9.453 9.453 0 0 1-1.315-.393 2.005 2.005 0 0 0-2.489 1.006l-.22.453a12.227 12.227 0 0 1-2.67-2.013 12.256 12.256 0 0 1-2.008-2.677l.422-.282a2.015 2.015 0 0 0 1.004-2.496c-.16-.43-.29-.871-.392-1.319a7.487 7.487 0 0 1-.12-.684A3.013 3.013 0 0 0 8.05 2H5.04a3.007 3.007 0 0 0-2.29 1.025 3.026 3.026 0 0 0-.722 2.408c1.1 8.674 7.927 15.495 16.58 16.567h.381A3.006 3.006 0 0 0 22 18.97v-3.02a3.017 3.017 0 0 0-2.479-2.918zm.502 6.039c0 .29-.125.564-.341.755a1.052 1.052 0 0 1-.823.252C11.127 19.083 5.05 12.96 4.096 5.2a1.1 1.1 0 0 1 .25-.825c.19-.217.465-.342.753-.342H8.11c.482-.011.903.322 1.004.795.04.275.09.547.15.815.116.53.27 1.052.462 1.56l-1.405.654a1.005 1.005 0 0 0-.492 1.339 14.563 14.563 0 0 0 7.025 7.046 1 1 0 0 0 .763 0c.253-.091.459-.28.572-.524l.622-1.409c.52.186 1.049.34 1.586.463.268.06.539.11.813.151.47.101.803.524.793 1.007l.02 3.14z'
    ],
    box: '0 0 24 24'
  },
  status: {
    path: [
      'M11.497 19.995H5.5a1 1 0 0 1-1-1V5a1 1 0 0 1 1-1h4.999v3a3 3 0 0 0 2.999 2.999h2.999v4.998a1 1 0 1 0 2 0V8.938a1.31 1.31 0 0 0-.06-.27v-.09a1.07 1.07 0 0 0-.19-.28L12.246 2.3a1.07 1.07 0 0 0-.28-.19.29.29 0 0 0-.1 0 1.1 1.1 0 0 0-.31-.11H5.5a3 3 0 0 0-3 3v13.995a3 3 0 0 0 3 3h5.997a1 1 0 0 0 0-2zm1-14.586 2.59 2.59h-1.59a1 1 0 0 1-1-1v-1.59zm-4.999 8.588h5.999a1 1 0 0 0 0-2H7.498a1 1 0 0 0 0 2zm4 1.999h-4a1 1 0 0 0 0 2h4a1 1 0 0 0 0-2zm-4-5.998h1a1 1 0 0 0 0-2h-1a1 1 0 0 0 0 2zm13.706 6.288a1 1 0 0 0-1.42 0l-3.288 3.299-1.29-1.3a1.004 1.004 0 1 0-1.42 1.42l2 2a1 1 0 0 0 1.42 0l3.998-4a1 1 0 0 0 0-1.42z'
    ],
    box: '0 0 24 24'
  },
  gear: {
    path: [
      'M20.218 12.661a1 1 0 0 1 0-1.32l1.28-1.44a1 1 0 0 0 .12-1.17l-2-3.46a1 1 0 0 0-1.07-.48l-1.88.38a1 1 0 0 1-1.15-.66l-.61-1.83a1 1 0 0 0-.95-.68h-4a1 1 0 0 0-1 .68l-.56 1.83a1 1 0 0 1-1.15.66l-1.93-.38a1 1 0 0 0-1 .48l-2 3.46a1 1 0 0 0 .1 1.17l1.27 1.44a1 1 0 0 1 0 1.32l-1.27 1.44a1 1 0 0 0-.1 1.17l2 3.46a1 1 0 0 0 1.07.48l1.88-.38a1 1 0 0 1 1.15.66l.61 1.83a1 1 0 0 0 1 .68h4a1 1 0 0 0 .95-.68l.61-1.83a1 1 0 0 1 1.15-.66l1.88.38a1 1 0 0 0 1.07-.48l2-3.46a1 1 0 0 0-.12-1.17l-1.35-1.44zm-1.49 1.34.8.9-1.28 2.22-1.18-.24a3 3 0 0 0-3.45 2l-.38 1.12h-2.56l-.36-1.14a3 3 0 0 0-3.45-2l-1.18.24-1.3-2.21.8-.9a3 3 0 0 0 0-4l-.8-.9 1.28-2.2 1.18.24a3 3 0 0 0 3.45-2l.38-1.13h2.56l.38 1.14a3 3 0 0 0 3.45 2l1.18-.24 1.28 2.22-.8.9a3 3 0 0 0 0 3.98zm-6.77-6a4 4 0 1 0 0 8 4 4 0 0 0 0-8zm0 6a2 2 0 1 1 0-4 2 2 0 0 1 0 4z'
    ],
    box: '0 0 24 24'
  },
  money_stack: {
    path: [
      'M22 17H2a1 1 0 0 0 0 2h20a1 1 0 0 0 0-2zm0 4H2a1 1 0 0 0 0 2h20a1 1 0 0 0 0-2zM6 7a1 1 0 1 0 0 2 1 1 0 0 0 0-2zm14-6H4a3 3 0 0 0-3 3v8a3 3 0 0 0 3 3h16a3 3 0 0 0 3-3V4a3 3 0 0 0-3-3zm1 11a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V4a1 1 0 0 1 1-1h16a1 1 0 0 1 1 1v8zm-9-7a3 3 0 1 0 0 6 3 3 0 0 0 0-6zm0 4a1 1 0 1 1 0-2 1 1 0 0 1 0 2zm6-2a1 1 0 1 0 0 2 1 1 0 0 0 0-2z'
    ],
    box: '0 0 24 24'
  },
  invoice: {
    path: [
      'M13 16H7a1 1 0 1 0 0 2h6a1 1 0 1 0 0-2zm-4-6h2a1 1 0 0 0 0-2H9a1 1 0 1 0 0 2zm12 2h-3V3a1 1 0 0 0-1.5-.87l-3 1.72-3-1.72a1 1 0 0 0-1 0l-3 1.72-3-1.72A1 1 0 0 0 2 3v16a3 3 0 0 0 3 3h14a3 3 0 0 0 3-3v-6a1 1 0 0 0-1-1zM5 20a1 1 0 0 1-1-1V4.73l2 1.14a1.08 1.08 0 0 0 1 0l3-1.72 3 1.72a1.08 1.08 0 0 0 1 0l2-1.14V19a3 3 0 0 0 .18 1H5zm15-1a1 1 0 1 1-2 0v-5h2v5zm-7-7H7a1 1 0 1 0 0 2h6a1 1 0 1 0 0-2z'
    ],
    box: '0 0 24 24'
  },
  laporan: {
    path: [
      'M9 10h1a1 1 0 1 0 0-2H9a1 1 0 1 0 0 2zm5.21 2.39-1.67 2.15-.87-.78a1 1 0 0 0-1.45.11l-2 2.5a1 1 0 1 0 1.56 1.25l1.34-1.67.88.8a1 1 0 0 0 1.46-.14l2.33-3a1 1 0 0 0-1.58-1.22zm5.78-3.45a1 1 0 0 0-.28-.65l-6-6a.99.99 0 0 0-.3-.2l-.08-.02a.98.98 0 0 0-.27-.06L13 2H7a3 3 0 0 0-3 3v14a3 3 0 0 0 3 3h10a3 3 0 0 0 3-3V9l-.01-.06zM14 5.4 16.59 8H15a1 1 0 0 1-1-1V5.41zM18 19a1 1 0 0 1-1 1H7a1 1 0 0 1-1-1V5a1 1 0 0 1 1-1h5v3a3 3 0 0 0 3 3h3v9z'
    ],
    box: '0 0 24 24'
  }
};

export default icons;
