import authentication from 'repository/Authentication';
import cookie from 'react-cookies';
import history from 'router/history';
import { removeLocalStorage, setLocalStorage } from 'services/localStorage';
import { authFail, authFailReset, setCurrentUser } from './auth.actions';
import { toast } from 'react-toastify';
import { capitalizeReplaceEtc } from 'util/regex';

export const authLogin =
  (userData, setIsShowLoading = () => {}, language, captcha, setAcceptReset = () => {}) =>
  dispatch => {
    authentication
      .login({
        param: userData,
        loading: setIsShowLoading,
        language: language
      })
      .then(res => {
        if (res.status === 200) {
          cookie.save('token', res.token, {
            path: '/',
            maxAge: 12600
          });
          history.push('/');
          dispatch(setCurrentUser(res));
          setLocalStorage('data-signin', res.data);
        } else {
          if (res?.data?.message) {
            dispatch(authFail(res?.data?.message));
            toast.error(capitalizeReplaceEtc(res?.data?.message), { hideProgressBar: true });
          }
          captcha.reset();
          setAcceptReset(true);
        }
      });
  };

export const authLogout =
  (language, setIsShowLoading = () => {}) =>
  dispatch => {
    authentication
      .logout({
        loading: setIsShowLoading,
        language: language
      })
      .then(res => {
        if (res.status === 200) {
          cookie.remove('token', { path: '/' });
          history.push('/');
          removeLocalStorage('data-signin');
          dispatch(setCurrentUser({}));
        }
      });
  };

export const authResetChange = () => dispatch => {
  dispatch(authFailReset());
};
