import React from 'react';
import Text from 'lib/components/Text';
import style from './Footer.module.scss';
import { useTranslation } from 'react-i18next';

const Footer = () => {
  const { t } = useTranslation();
  return (
    <div className={style.footer}>
      <Text style={{ fontSize: 12, color: '#42505c' }}>
        &copy; {new Date().getFullYear()} {t('common:label.copryight')}
      </Text>
    </div>
  );
};

export default Footer;
