import React, { useState } from 'react';
import cn from 'classnames';
import Icon from 'icon';
import Text from 'lib/components/Text';
import PropTypes from 'prop-types';
import randomId from 'util/randomId';
import style from './PasswordField.module.scss';

const PasswordField = props => {
  const [getId] = useState(randomId());
  const [visible, setVisible] = useState(false);
  const { onChange, type, className, value, prefix, suffix, alert, showAlert, ...rest } = props;

  return (
    <div className={cn(className)}>
      <div className={cn(style.input)}>
        <Icon name="lock" size={22} className={cn(style.input__icon, style.input__icon_prefix)} />

        <input
          {...rest}
          id={getId}
          type={visible ? 'text' : 'password'}
          value={value}
          className={style.input__field}
          onChange={onChange}
        />

        <button
          type="button"
          onClick={() => setVisible(!visible)}
          className={cn(style.input__icon, style.input__icon_suffix)}
        >
          <Icon name="eye" size={22} />
        </button>
      </div>{' '}
      {Boolean(showAlert) && (
        <Text tag="span" className={cn('text-danger text-xs')}>
          {alert}
        </Text>
      )}
    </div>
  );
};

PasswordField.propTypes = {
  onChange: PropTypes.func.isRequired,
  className: PropTypes.string,
  value: PropTypes.string,
  alert: PropTypes.string,
  showAlert: PropTypes.bool
};

export default PasswordField;
