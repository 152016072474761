import React, { useRef, useState, Fragment } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Text from 'lib/components/Text';
import Icon from 'icon';
import Flex from 'lib/containers/Flex';
import useOutsideEvent from 'hook/useOutsideEvent';
import { authLogout } from 'services/auth';
import ButtonLanguage from 'lib/components/Button/ButtonLanguage';
import style from './Header.module.scss';
import ChangePassword from './ChangePassword';
import authentication from '../../../repository/Authentication';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';

const Header = () => {
  const userMenuRef = useRef(null);
  const [showChangePassword, setShowChangePassword] = useState(false);
  const [openSub, setOpenSub] = useState(false);
  const [isShowLoading, setIsShowLoading] = useState(false);
  const [isShowLogoutLoading, setIsShowLogoutLoading] = useState(false);
  const auth = useSelector(state => state.auth);
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();
  useOutsideEvent(userMenuRef, () => setOpenSub(false));

  const actionSubmitResetPin = (e, reset) => {
    authentication.resetPin({ param: e, loading: setIsShowLoading }).then(res => {
      if (res?.status === 200) {
        setShowChangePassword(false);
        reset();
        toast.success(
          <div>
            Success
            <br /> {t('common:settings.heading')}
          </div>,
          { hideProgressBar: true }
        );
      } else {
        setShowChangePassword(true);
        toast.error(
          <div>
            Error
            <br /> {res?.data?.message}
          </div>,
          { hideProgressBar: true }
        );
      }
    });
  };

  const actionOnCloseResetPin = reset => {
    setShowChangePassword(false);
    reset();
  };

  return (
    <Fragment>
      <div className={style.header}>
        <Text tag="h2" className="text-lg font-bold text-dark/42505c">
          {auth?.user?.profile?.partner_legal_name}
        </Text>

        <div className={style.header__menu}>
          <div className="mt-18">
            <ButtonLanguage withIcon={false} />
          </div>
          <div
            className="flex items-center ml-40"
            role="button"
            onClick={() => setOpenSub(!openSub)}
            ref={userMenuRef}
          >
            <div className="flex items-center">
              <img
                src="https://via.placeholder.com/40x40"
                alt="avatar"
                className={style.header__menu_avatar}
              />

              <div className="mr-22">
                <div className={style.header__menu_name}>{auth?.user?.profile?.name}</div>
                <div className={style.header__menu_role}>{auth?.user?.profile?.roles[0]}</div>
              </div>

              <Icon name="down_curret" />
            </div>

            {openSub && (
              <div
                style={{ width: 180, top: 90 }}
                className="absolute right-0 p-16 bg-white rounded-md top-full z-100"
              >
                <Text className="mb-16 text-xs text-gray/bac0c7">SETTINGS</Text>

                <button
                  type="button"
                  onClick={() => setShowChangePassword(true)}
                  className="w-full pb-16 mb-16 text-sm border-b border-gray/eaecef"
                >
                  <Flex items="center" gap={8}>
                    <Icon name="lock" size={18} />
                    {t('common:settings.heading')}
                  </Flex>
                </button>

                <button
                  type="button"
                  onClick={() => dispatch(authLogout(i18n.language, setIsShowLogoutLoading))}
                  disabled={isShowLogoutLoading}
                  className="w-full text-sm"
                >
                  {isShowLogoutLoading ? (
                    <Flex items="center" gap={8}>
                      <svg className="animate-spin h-5 w-5 mr-3" viewBox="0 0 24 24">
                        ...
                      </svg>
                      {t('common:label.waiting')} ...
                    </Flex>
                  ) : (
                    <Flex items="center" gap={8}>
                      <Icon name="keluar" size={18} />
                      {t('common:label.logout')}
                    </Flex>
                  )}
                </button>
              </div>
            )}
          </div>
        </div>
      </div>

      <ChangePassword
        shown={showChangePassword}
        onClose={actionOnCloseResetPin}
        isShowLoading={isShowLoading}
        actionSubmitResetPin={actionSubmitResetPin}
      />
    </Fragment>
  );
};

export default Header;
