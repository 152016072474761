import jmespath from 'jmespath';
import { toast } from 'react-toastify';
import {
  apiGetWithoutToken,
  apiPostWithTokenLanguage,
  apiPutWithTokenLanguage
} from 'services/api/index';
import { PATH_ADMIN } from 'services/path/admin';
import responseGlobal from '../ResponseGlobal/responseGlobal';

async function requestForgotPasswordEmail(props) {
  const loading = props.loading ? props.loading : function () {};
  let response = '';
  loading(true);
  try {
    response = await apiPostWithTokenLanguage(
      PATH_ADMIN.FORGOT_PASSWORD,
      props.param,
      props.language
    );
    response = jmespath.search(response, responseGlobal);
    loading(false);
  } catch (error) {
    response = jmespath.search(error.response, responseGlobal);
    toast.error(
      <div>
        Error
        <br /> {error?.response?.data?.message}
      </div>,
      { hideProgressBar: true }
    );
    loading(false);
  }

  return response;
}

async function validateForgotPasswordEmail(props) {
  const loading = props.loading ? props.loading : function () {};
  let response = '';
  loading(true);
  try {
    response = await apiGetWithoutToken(`${PATH_ADMIN.FORGOT_PASSWORD}/${props.unique_id}`);
    response = jmespath.search(response, responseGlobal);
    loading(false);
  } catch (error) {
    response = jmespath.search(error.response, responseGlobal);
    loading(false);
  }

  return response;
}

async function resetPasswordEmail(props) {
  const loading = props.loading ? props.loading : function () {};
  let response = '';
  loading(true);
  try {
    response = await apiPutWithTokenLanguage(
      `${PATH_ADMIN.FORGOT_PASSWORD}/${props.unique_id}/reset`,
      props.param,
      props.language
    );
    response = jmespath.search(response, responseGlobal);
    loading(false);
  } catch (error) {
    response = jmespath.search(error.response, responseGlobal);
    toast.error(
      <div>
        Error
        <br /> {error?.response?.data?.message}
      </div>,
      { hideProgressBar: true }
    );
    loading(false);
  }

  return response;
}

const forgotPasswordRepository = {
  requestForgotPasswordEmail,
  validateForgotPasswordEmail,
  resetPasswordEmail
};

export default forgotPasswordRepository;
