import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import cookie from 'react-cookies';
import PATH_URL from './path';

const PublicRoute = ({ component: Component, auth = cookie.load('token'), ...rest }) => (
  <Route
    {...rest}
    render={props => {
      return auth ? (
        <Redirect to={{ pathname: PATH_URL.DASHBOARD, state: { from: props.location } }} />
      ) : (
        <Component {...props} />
      );
    }}
  />
);

export default PublicRoute;
