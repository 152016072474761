import CryptoJS from 'crypto-js';

const cryptKey = '2768190d8afc54be5dac0c87efe836e3';

// export const encryptPassword = function (password) {
//   let key = CryptoJS.enc.Utf8.parse(cryptKey);
//   let iv = CryptoJS.enc.Utf8.parse('1234567812345678');
//   let encrypted = CryptoJS.AES.encrypt(password, key, {
//     iv: iv,
//     padding: CryptoJS.pad.Pkcs7
//   });
//   return encrypted.toString();
// };

const createRandomIv = function () {
  const keySize = 192 / 32;
  const ivSize = 128 / 32;
  const evp = CryptoJS.algo.EvpKDF.create({
    keySize: keySize + ivSize,
    hasher: CryptoJS.algo.SHA1
  }).compute(cryptKey);
  const iv = CryptoJS.lib.WordArray.create(evp.words.slice(keySize), ivSize * 4);
  return iv.toString();
};

export const encryptPassword = function (password) {
  const iv = createRandomIv();
  const hash = CryptoJS.AES.encrypt(password, cryptKey, {
    iv,
    mode: CryptoJS.mode.CTR
  });
  const base64 = CryptoJS.enc.Base64.parse(hash.toString());
  const eHex = base64.toString(CryptoJS.enc.Hex);

  return `${iv}:${eHex}`;
};
