import { FallbackLoading } from 'lib/components/Loading';
import NotFound from 'lib/components/NotFound';
import ForgotPassword from 'page/ForgotPassword';
import Login from 'page/Login';
import ResetPassword from 'page/ResetPassword';
import ResetSuccess from 'page/ResetSuccess';
import Expired from 'page/Expired';
import React, { lazy, Suspense } from 'react';
import { Route, Router, Switch } from 'react-router-dom';
import history from './history';
import PATH_URL from './path';
import PrivateRoute from './PrivateRoute';
import PublicRoute from './PublicRoute';
import VerifyRoute from './VerifyRoute';
import NotAuthorized from 'lib/components/NotAuthorized';

const RouteManager = () => (
  <Suspense fallback={<FallbackLoading />}>
    <Router history={history}>
      <Switch>
        <PublicRoute exact path={PATH_URL.HOME} component={Login} />
        <PublicRoute path={PATH_URL.FORGOT_PASSWORD} component={ForgotPassword} />
        <PublicRoute path={`${PATH_URL.RESET_PASSWORD}/:id`} component={ResetPassword} />
        <PublicRoute path={PATH_URL.RESET_PASSWORD_SUCCESS} component={ResetSuccess} />
        <PublicRoute path={PATH_URL.EXPIRED} component={Expired} />
        <PrivateRoute path={PATH_URL.DASHBOARD}>
          <Suspense fallback={<FallbackLoading />}>
            <Switch>
              <VerifyRoute
                exact
                path={PATH_URL.DASHBOARD}
                component={lazy(() => import('page/Home'))}
              />
              <VerifyRoute
                path={PATH_URL.REGISTRASI}
                component={lazy(() => import('page/Register'))}
              />
              <VerifyRoute
                path={PATH_URL.DEVELOPER_TOOLS}
                component={lazy(() => import('page/DevloperTools'))}
              />
              <VerifyRoute
                path={PATH_URL.PENCAIRAN}
                component={lazy(() => import('page/Pinjaman'))}
              />
              <VerifyRoute
                path={PATH_URL.DAFTAR_PINJAMAN}
                component={lazy(() => import('page/DaftarPinjaman'))}
              />
              <VerifyRoute
                path={PATH_URL.PEMBAYARAN_SELESAI}
                component={lazy(() => import('page/Pembayaran/PembayarnSelesai'))}
              />
              <VerifyRoute
                path={PATH_URL.PEMBAYARAN_BARU}
                component={lazy(() => import('page/Pembayaran/PembayaranBaru'))}
              />
              <VerifyRoute
                path={PATH_URL.MATURED_LOAN}
                component={lazy(() => import('page/Pembayaran/MaturedLoan'))}
              />
              <VerifyRoute
                path={PATH_URL.KELOLA_PENGGUNA}
                component={lazy(() => import('page/KelolaPengguna'))}
              />
              <VerifyRoute path={PATH_URL.LAPORAN} component={lazy(() => import('page/Laporan'))} />
              <Route path="*">
                <NotFound linkTo={PATH_URL.DASHBOARD} />
              </Route>
            </Switch>
          </Suspense>
        </PrivateRoute>

        {/* Not Authorized Page : still have access to another page depends on account role */}
        <PrivateRoute path={PATH_URL.NOT_AUTHORIZED}>
          <NotAuthorized />
        </PrivateRoute>

        <Route path="*">
          <NotFound />
        </Route>
      </Switch>
    </Router>
  </Suspense>
);

export default RouteManager;
