import React, { useEffect, useRef } from 'react';
import Fade from 'react-reveal/Fade';
import cn from 'classnames';
import Text from 'lib/components/Text';
import Icon from 'icon';
import useOutsideEvent from 'hook/useOutsideEvent';
import style from './FadeDrawer.module.scss';

const FadeDrawer = props => {
  const { children, shown, title, width, onClose, useOutsideEvent = true } = props;
  const wrapperRef = useRef(null);

  useEffect(() => {
    const masterContent = document.getElementById('master-content');
    const findMe = document?.getElementById('fade-drawer');

    if (findMe) masterContent.classList.add('overflow-hidden');
    if (!findMe) masterContent.classList.remove('overflow-hidden');
  }, [shown]);

  if (useOutsideEvent) {
    useOutsideEvent(wrapperRef, () => {
      onClose();
    });
  }

  return (
    <div
      className={cn(style.drawer, { [style.drawer_hidden]: !shown })}
      id={shown ? 'fade-drawer' : ''}
    >
      <Fade right when={shown} delay={20} duration={500}>
        <div>
          <div ref={wrapperRef}>
            <header className={style.drawer__header} style={{ width }}>
              <Text className="text-lg font-bold">{title}</Text>

              <button type="button" onClick={onClose}>
                <Icon name="close" size={20} />
              </button>
            </header>

            <div className={cn(style.drawer__content)} style={{ width }}>
              {children}
            </div>
          </div>
        </div>
      </Fade>
    </div>
  );
};

export default FadeDrawer;
